/* istanbul ignore file */
import { Input, Select, TextArea } from "@hexa-ui/components";
import { InfraFormContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { handleChange } from "../../InfraSupportFunctions";
import { HeadingStyled } from "./GeneralQuestionsFormsStyled";

export const GeneralQuestionsForm = () => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const [isNewResource, setIsNewResource] = useState<boolean>(false);
	const {
		control,
		getValues,
		register,
		formState: { errors }
	} = useFormContext();
	const { pathname } = useLocation();

	const handleSummary = (event) => {
		setInfraFormData((previousState) => ({
			...previousState,
			summary: event.target.value
		}));
	};

	useEffect(() => {
		pathname.includes("new-resource") && setIsNewResource(true);
	}, [isNewResource]);

	return (
		<ContainerCardStyled border="small" elevated="medium">
			<HeadingStyled size="H3">General Questions</HeadingStyled>
			<ContainerStyled marginBottom marginTop>
				<TextArea
					{...register("summary", {
						onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => handleSummary(event)
					})}
					id="text-area-summary"
					label="Summary"
					width="100%"
					value={infraFormData?.summary}
					placeholder="Short description"
				/>
			</ContainerStyled>
			{isNewResource && (
				<ContainerStyled marginBottom marginTop>
					<Input
						{...register("General_questions.landing_zone", {
							onChange: () => handleChange(getValues, setInfraFormData)
						})}
						id="landing_zone"
						label="Landing Zone"
						value={infraFormData?.General_questions?.landing_zone}
						hasError={!!errors?.General_questions?.["landing_zone"]}
						errorText={errors?.General_questions?.["landing_zone"]?.message}
						width="100%"
						placeholder="Landing Zone"
					/>
				</ContainerStyled>
			)}
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register("General_questions.team_name", {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="team_name"
					label="Team Name"
					value={infraFormData?.General_questions?.team_name}
					hasError={!!errors?.General_questions?.["team_name"]}
					errorText={errors?.General_questions?.["team_name"]?.message}
					width="100%"
					placeholder="Team Name"
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register("General_questions.director_email", {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="director_email"
					label="Director Email"
					value={infraFormData?.General_questions?.director_email}
					hasError={!!errors?.General_questions?.["director_email"]}
					errorText={errors?.General_questions?.["director_email"]?.message}
					width="100%"
					placeholder="example@ab-inbev.com"
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<Controller
					control={control}
					name="General_questions.environments"
					render={({ field: { onChange } }) => {
						return (
							<Select.Root
								id="environments"
								label="Environments"
								labelProps={{ htmlFor: "environments", className: "label" }}
								value={infraFormData?.General_questions?.environments}
								data-testid="environments"
								placeholder="Choose an option"
								error={errors?.General_questions?.["environments"]?.message}
								onChange={(value) => {
									onChange(value);
									handleChange(getValues, setInfraFormData);
								}}
								multiple
							>
								<Select.Option value="sit">sit</Select.Option>
								<Select.Option value="uat">uat</Select.Option>
								<Select.Option value="prd">prd</Select.Option>
							</Select.Root>
						);
					}}
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<TextArea
					{...register("General_questions.admin_users", {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="admin_users"
					label="List of admin users (email)"
					hint="ABI emails only"
					value={infraFormData?.General_questions?.admin_users}
					width="100%"
					placeholder="example@ab-inbev.com, example@ab-inbev.com"
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<TextArea
					{...register("General_questions.non_admin_users", {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="non_admin_users"
					label="List of non admin users (email)"
					hint="ABI emails only"
					value={infraFormData?.General_questions?.non_admin_users}
					width="100%"
					placeholder="example@ab-inbev.com, example@ab-inbev.com"
				/>
			</ContainerStyled>
		</ContainerCardStyled>
	);
};
