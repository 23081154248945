/* istanbul ignore file */
import { ArrowLeft, BarChart, TrendinigUp } from "@hexa-ui/icons";
import { useMemo } from "react";
import { routes } from "router/constants";
import { ESidebarVisibility, ISidebarItem } from "types/Sidebar.types";

interface IUseSidebarItem {
	sidebarLinks: ISidebarItem[];
}

export const useMenuUtils = () => {
	const sidebarUtilsLinks = useMemo<ISidebarItem[]>(
		() => [
			{
				id: "6",
				title: "Back to BEES One",
				icon: () => <ArrowLeft />,
				path: routes.BEES_ONE,
				visible: ESidebarVisibility.AllUsers,
				target: "_self"
			}
		],
		[]
	);

	return { sidebarUtilsLinks };
};

export const useMenuItems = (): IUseSidebarItem => {
	const sidebarLinks = useMemo<ISidebarItem[]>(
		() => [
			{
				id: "2",
				// istanbul ignore next
				title: "Global Reports",
				icon: () => <BarChart />,
				path: routes.GLOBAL_REPORTS.BASE_GLOBAL_REPORTS,
				visible: ESidebarVisibility.BeesDataPortalUsers
			},
			{
				id: "4",
				// istanbul ignore next
				title: "Usage Metrics",
				icon: () => <TrendinigUp />,
				path: routes.USAGE_METRICS,
				visible: ESidebarVisibility.BeesUsers
			}
		],
		[]
	);

	return { sidebarLinks };
};
