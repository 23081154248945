import { Button, IconButton } from "@hexa-ui/components";
import { Edit2, Trash2 } from "@hexa-ui/icons";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { routes } from "router/constants";
import { convertToLowerCaseWithDash } from "utils/transformers";
import { DeleteDatasetModal } from "../DeleteDatasetModal/DeleteDatasetModal";
import { EditionButtonsWrapper } from "./EditionButtonsStyled";

interface IEditionButtonsProps {
	datasetId: string;
	domainName: string;
}

export const EditionButtons = ({ datasetId, domainName }: IEditionButtonsProps) => {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const navigate = useNavigate();

	const handleDeleteButtonClick = () => {
		setIsDeleteModalOpen(true);
	};

	// istanbul ignore next
	const handleCloseDeleteModal = () => {
		// istambul ignore next
		setIsDeleteModalOpen(false);
	};

	return (
		<EditionButtonsWrapper>
			<IconButton
				data-testid="delete-dataset-button"
				icon={Trash2}
				size="medium"
				variant="tertiary"
				onClick={handleDeleteButtonClick}
			/>
			<Button
				data-testid="edit-dataset-button"
				variant="secondary"
				leading
				icon={Edit2}
				size="medium"
				onClick={() =>
					navigate(
						generatePath(routes.SELF_SERVICE_BI.EDIT_DATASET, {
							domainName: convertToLowerCaseWithDash(domainName),
							datasetId: datasetId
						})
					)
				}
			>
				Edit dataset
			</Button>
			{isDeleteModalOpen && (
				<DeleteDatasetModal
					datasetId={datasetId}
					isOpen={isDeleteModalOpen}
					onClose={handleCloseDeleteModal}
				/>
			)}
		</EditionButtonsWrapper>
	);
};
