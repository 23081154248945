import { TypeToast } from "admin-portal-shared-services";
import { useLoaderContext } from "contexts";
import { IRequestTypeInfo } from "pages/AccessManagementPages/RequestAccessManagementPage/RequestAccessManagementPage.types";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getRequestFields } from "services/Jira/JiraService";
import { INITIAL_STATUS_CODE } from "utils/constants";
import { showNotify } from "utils/notify";

export const useGetJiraData = (requestType: number, serviceDeskId: string) => {
	const { formatMessage } = useIntl();
	const [requestTypeInfo, setRequestTypeInfo] = useState<IRequestTypeInfo[]>([]);
	const [statusCode, setStatusCode] = useState<number>(INITIAL_STATUS_CODE);
	const [errorHasOccurred, setErrorHasOccurred] = useState<boolean>(false);
	const { setIsLoading } = useLoaderContext();

	const getRequestTypeInfo = async () => {
		try {
			setIsLoading(true);
			const response = await getRequestFields(requestType, serviceDeskId);
			setRequestTypeInfo(response?.data?.requestTypeFields);
			setStatusCode(response.status);
		} catch {
			// istanbul ignore next
			showNotify(formatMessage({ id: "JIRA.TOAST_LOAD_ERROR" }), TypeToast.ERROR);
			// istanbul ignore next
			setErrorHasOccurred(true);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getRequestTypeInfo();
	}, []);

	return {
		requestTypeInfo,
		errorHasOccurred,
		setErrorHasOccurred,
		statusCode
	};
};
