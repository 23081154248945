/* istanbul ignore file */
import { createContext, ReactNode, useContext, useState } from "react";
import { EBeesDomains } from "types/Domain.types";
import { IReport } from "types/Report.types";

interface IReportsContextProps {
	updateReportsDate: Date;
	setUpdateReportsDate: (value: Date) => void;
	dashboardCards: IReport[];
	setDashboardCards: (value) => void;
	tabDomain: string | EBeesDomains;
	setTabDomain: (value) => void;
}

const INITIAL_TAB = EBeesDomains.Force;

export const Reports = createContext<IReportsContextProps>({
	updateReportsDate: null,
	setUpdateReportsDate: () => null,
	dashboardCards: [] as IReport[],
	setDashboardCards: () => null,
	tabDomain: "",
	setTabDomain: () => null
});

export const useReportsContext = () => useContext(Reports);

export const ReportsProvider = ({ children }: { children: ReactNode }) => {
	const [updateReportsDate, setUpdateReportsDate] = useState(null);
	const [dashboardCards, setDashboardCards] = useState([]);
	const [tabDomain, setTabDomain] = useState(INITIAL_TAB);

	return (
		<Reports.Provider
			value={{
				updateReportsDate,
				setUpdateReportsDate,
				dashboardCards,
				setDashboardCards,
				tabDomain,
				setTabDomain
			}}
		>
			{children}
		</Reports.Provider>
	);
};
