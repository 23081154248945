/* istanbul ignore file */
import { Checkbox, Input, Select } from "@hexa-ui/components";
import { ScopesCheckboxesStyled } from "components/Modals/ReportModal/ReportModalStyled";
import { InfraFormContext } from "contexts";
import { useValidateUniqueValues } from "hooks/useValidateUniqueValues";
import { FormHeader } from "pages/InfraServicesPages/components/FormHeader";
import { infraStacksInfo } from "pages/InfraServicesPages/components/InfraStacksInfo";
import { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { ParagraphStyled } from "styles/shared/ParagraphStyled/ParagraphStyled";
import { IStackFieldsProps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { handleChange, setupInput } from "../../InfraSupportFunctions";
import { YesOrNoRadio } from "../../components/YesOrNoRadio";
import { BasicStackForm } from "../BasicStack/BasicStackForm";
import { EventHubSkuOptions } from "../InfraFormOptions";
import { EventHubForm } from "./EventHubForm";
import { AlertStyled, DividerStyled } from "./StreamingStackStyled";

export const StreamingStackFields = ({
	index,
	removeItem,
	fields: stackFields,
	fieldId
}: IStackFieldsProps) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const stackInfo = infraStacksInfo.filter((stack) => stack.id === "streaming-stack")?.[0];
	const {
		register,
		getValues,
		control,
		setValue,
		watch,
		formState: { errors }
	} = useFormContext();
	const DEDICATED_CLUSTER_FIELD =
		infraFormData?.streaming_stack?.[index]?.event_hub_dedicated_cluster;
	const EVENT_HUB_SKU_FIELD = infraFormData?.streaming_stack?.[index]?.event_hub_namespace_sku;
	const formValues = watch("streaming_stack");
	const error = useValidateUniqueValues(formValues, index, "product_name", "Product Name");

	useEffect(() => {
		setValue(`streaming_stack.${index}.id`, fieldId);
	}, [fieldId, infraFormData]);

	return (
		<ContainerCardStyled
			border="small"
			elevated="medium"
			key={fieldId}
			data-testid="streaming-stack-form"
		>
			<FormHeader
				stackInfo={stackInfo}
				fields={stackFields}
				removeItem={removeItem}
				index={index}
				fieldId={fieldId}
				formSection="streaming_stack"
			/>
			<BasicStackForm
				acceptsShortProductName
				formSection="streaming_stack"
				index={index}
				error={error}
			/>
			<ContainerStyled marginBottom marginTop>
				<Controller
					control={control}
					name={`streaming_stack.${index}.event_hub_namespace_sku`}
					render={({ field: { onChange } }) => {
						return (
							<Select.Root
								id="event_hub_namespace_sku"
								data-testid="event_hub_namespace_sku"
								label="Event Hub Namespace SKU"
								labelProps={{ htmlFor: "event_hub_namespace_sku", className: "label" }}
								value={infraFormData?.streaming_stack?.[index]?.event_hub_namespace_sku}
								onChange={onChange}
								error={errors?.streaming_stack?.[index]?.["event_hub_namespace_sku"]?.message}
							>
								<EventHubSkuOptions />
							</Select.Root>
						);
					}}
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				{EVENT_HUB_SKU_FIELD === "Basic" || EVENT_HUB_SKU_FIELD === "Standard" ? (
					<Controller
						control={control}
						name={`streaming_stack.${index}.event_hub_namespace_capacity`}
						render={({ field: { onChange } }) => {
							return (
								<Select.Root
									id="event_hub_namespace_capacity"
									data-testid="event_hub_namespace_capacity"
									label="Event Hub Namespace SKU"
									labelProps={{ htmlFor: "event_hub_namespace_capacity", className: "label" }}
									value={infraFormData?.streaming_stack?.[index]?.event_hub_namespace_capacity}
									error={
										errors?.streaming_stack?.[index]?.["event_hub_namespace_capacity"]?.message
									}
									onChange={onChange}
								>
									<Select.Option value="1">1</Select.Option>
									<Select.Option value="2">2</Select.Option>
								</Select.Root>
							);
						}}
					/>
				) : (
					<Input
						{...register(`streaming_stack.${index}.event_hub_namespace_capacity`, {
							onChange: () => handleChange(getValues, setInfraFormData)
						})}
						id="event_hub_namespace_capacity"
						type="number"
						label="Event Hub Namespace Capacity"
						width="100%"
						placeholder=""
						{...setupInput(
							errors,
							"streaming_stack",
							"event_hub_namespace_capacity",
							index,
							infraFormData
						)}
					/>
				)}
			</ContainerStyled>
			<ContainerStyled>
				<ParagraphStyled size="small" weight="semibold">
					Is Auto-Inflate enabled on this Event Hub?
				</ParagraphStyled>
				<YesOrNoRadio
					id="event_hub_namespace_auto_inflate_enable"
					fieldData="event_hub_namespace_auto_inflate_enable"
					formSection="streaming_stack"
					index={index}
				/>
			</ContainerStyled>
			<ContainerStyled>
				<ParagraphStyled size="small" weight="semibold">
					Does the namespace has data redundancy in other regions (zones)?
				</ParagraphStyled>
				<YesOrNoRadio
					id="event_hub_namespace_zones"
					fieldData="event_hub_namespace_zones"
					formSection="streaming_stack"
					index={index}
				/>
			</ContainerStyled>
			<ContainerStyled>
				<ParagraphStyled size="small" weight="semibold">
					Is a Dedicated Cluster needed?
				</ParagraphStyled>
				<YesOrNoRadio
					id="event_hub_dedicated_cluster"
					fieldData="event_hub_dedicated_cluster"
					formSection="streaming_stack"
					index={index}
				/>
				{DEDICATED_CLUSTER_FIELD && (
					<AlertStyled message="Please notice this will greately increase the cost" />
				)}
			</ContainerStyled>
			<ContainerStyled>
				<ScopesCheckboxesStyled>
					<Controller
						control={control}
						name={`streaming_stack.${index}.event_hub_namespace_public_access`}
						render={({ field: { onChange } }) => {
							return (
								<Checkbox
									id="event_hub_namespace_public_access"
									label="Allow Public Access"
									size="small"
									checked={
										infraFormData?.streaming_stack?.[index]?.event_hub_namespace_public_access
									}
									onCheckedChange={(value) => {
										onChange(value);
										handleChange(getValues, setInfraFormData);
									}}
								/>
							);
						}}
					/>
				</ScopesCheckboxesStyled>
			</ContainerStyled>
			<DividerStyled />
			<EventHubForm formIndex={index} />
		</ContainerCardStyled>
	);
};
