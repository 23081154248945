import { Button, Dialog, Heading, Input, Paragraph } from "@hexa-ui/components";
import { TypeToast } from "admin-portal-shared-services";
import { useReportsContext } from "contexts";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { deleteReport } from "services/Report/ReportService";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { WrapperStyled } from "styles/shared/WrapperStyled/WrapperStyled";
import { IReport } from "types/Report.types";
import { showNotify } from "utils/notify";
import { DeleteDialogActionButtonStyled, FormStyled } from "./DeleteReportModalStyled";
import { reportDeleted } from "./deleteReportModalTracking";

const CONDITIONAL_DELETE = "DELETE";

export interface IDeleteReportModalProps {
	report: IReport;
	isOpen: boolean;
	onClose: () => void;
}

const DeleteReportModal = ({ report, isOpen, onClose }: IDeleteReportModalProps): JSX.Element => {
	const [inputValue, setInputValue] = useState<string>("");
	const { formatMessage } = useIntl();
	const { setUpdateReportsDate } = useReportsContext();

	const handleDelete = async (event) => {
		event.preventDefault();
		try {
			await deleteReport(report.reportId);
			setUpdateReportsDate(new Date());
			reportDeleted(report.reportId);
			// istanbul ignore next
			showNotify(formatMessage({ id: "REPORT.TOAST_DELETED_SUCCESS" }), TypeToast.SUCCESS);
		} catch {
			showNotify(formatMessage({ id: "REPORT.TOAST_DELETED_ERROR" }), TypeToast.ERROR);
		} finally {
			onClose();
		}
	};

	const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setInputValue(value);
	};

	const isActionButtonDisabled = () => {
		return inputValue === CONDITIONAL_DELETE ? false : true;
	};

	return (
		<Dialog.Root
			title={<Heading size="H2">Are you sure?</Heading>}
			destructive="critical"
			open={isOpen}
			onClose={onClose}
			onEscapeKeyDown={onClose}
		>
			<ContainerStyled data-testid="delete-report-modal" marginTop>
				<Paragraph>
					Deleting a dashboard also deletes it in the Domain page. Is that what you want to do?{" "}
					<b>(can't be undone)</b>
				</Paragraph>
				<WrapperStyled>
					<Heading size="H4">Type {CONDITIONAL_DELETE} in the box below to confirm</Heading>
				</WrapperStyled>
				<FormStyled onSubmit={handleDelete}>
					<ContainerStyled marginBottom marginTop>
						<Input
							id="input-with-dashboard-title"
							width="100%"
							placeholder={CONDITIONAL_DELETE}
							onChange={handleInputValue}
							value={inputValue}
							required
						/>
					</ContainerStyled>
					<DeleteDialogActionButtonStyled>
						<Button
							type="submit"
							data-testid="delete-button"
							size="medium"
							variant="destructive"
							disabled={isActionButtonDisabled()}
						>
							Delete Report
						</Button>
						<Dialog.Close>
							<Button size="medium" variant="secondary" onClick={onClose}>
								Cancel
							</Button>
						</Dialog.Close>
					</DeleteDialogActionButtonStyled>
				</FormStyled>
			</ContainerStyled>
		</Dialog.Root>
	);
};

export default DeleteReportModal;
