import { Grid } from "@hexa-ui/components";
import { setAppHeaderConfig } from "admin-portal-shared-services";
import { JiraForm } from "components/JiraForm/JiraForm";
import Loader from "components/shared/Loader/Loader";
import { useGetJiraData } from "hooks/useGetJiraData";
import ErrorComponent from "pages/ErrorPages/ErrorComponent";
import { useEffect } from "react";
import { routes } from "router/constants";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ERequestTypeId } from "types/Jira.types";
import { DATA_TEAM_SERVICE_DESK_ID } from "utils/constants";
import { DescriptionTenantForm } from "./DescriptionTenantForm";
import { tenantAccessFormSchema } from "./TenantAccessFormSchema";

const { Container } = Grid;

const TenantAccessPage = () => {
	const { TenantAccess } = ERequestTypeId;
	const { requestTypeInfo, errorHasOccurred, setErrorHasOccurred, statusCode } = useGetJiraData(
		TenantAccess,
		DATA_TEAM_SERVICE_DESK_ID
	);

	useEffect(() => {
		setAppHeaderConfig({
			pageTitle: "Tenant Access Request",
			breadcrumbConfig: {
				homePath: routes.HOME_PAGE,
				items: [
					{
						label: "Access Management",
						path: routes.ACCESS_MANAGEMENT.ACCESS_MANAGEMENT,
						isCurrentPage: false
					},
					{
						label: "Tenant Access Request",
						path: routes.ACCESS_MANAGEMENT.TENANT_ACCESS,
						isCurrentPage: true
					}
				]
			}
		});
	}, [setAppHeaderConfig]);

	// istanbul ignore next
	const handleReloadButtonClick = () => {
		// istanbul ignore next
		setErrorHasOccurred(false);
	};

	return (
		<ErrorComponent
			errorCode={statusCode}
			errorHasOccurred={errorHasOccurred}
			onClick={handleReloadButtonClick}
		>
			<Loader>
				<Container sidebar type="fluid" data-testid="tenant-access-page">
					<ContainerCardStyled border="small" elevated="medium">
						<DescriptionTenantForm />
						<JiraForm
							requestTypeId={TenantAccess}
							serviceDeskId={DATA_TEAM_SERVICE_DESK_ID}
							requestTypeInfo={requestTypeInfo}
							validationSchema={tenantAccessFormSchema}
							longTextFields={["description"]}
							hasError={errorHasOccurred}
						/>
					</ContainerCardStyled>
				</Container>
			</Loader>
		</ErrorComponent>
	);
};

export default TenantAccessPage;
