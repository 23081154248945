import { Badge, CardTypes, Grid, Heading, Paragraph } from "@hexa-ui/components";
import { Chart1 } from "assets/ReportsPreview/Chart1";
import { Chart2 } from "assets/ReportsPreview/Chart2";
import { Chart3 } from "assets/ReportsPreview/Chart3";
import { Chart7 } from "assets/ReportsPreview/Chart7";
import { Chart8 } from "assets/ReportsPreview/Chart8";
import { Chart9 } from "assets/ReportsPreview/Chart9";
import { reportClickedTracking } from "pages/GlobalReportsPage/globalReportsPageTracking";
import { useCallback, useEffect, useState } from "react";
import { generatePath, routes } from "router/constants";
import { convertToLowerCaseWithDash } from "utils/transformers";
import { DashboardCardStyled, LinkStyled } from "./DashboardCardStyled";

type ElevationType = CardTypes["elevated"];
const { Item } = Grid;

const reportImages = [Chart1, Chart2, Chart3, Chart7, Chart8, Chart9];
export interface IDashboardCardProps {
	domainName: string;
	reportId: string;
	reportTitle: string;
	status?: string;
	ownerName: string;
	ownerEmail: string;
}

export const DashboardCard = ({
	domainName,
	reportId,
	reportTitle,
	status,
	ownerName,
	ownerEmail
}: IDashboardCardProps): JSX.Element => {
	const [elevation, setElevation] = useState<ElevationType>("medium");
	const [reportImage, setReportImage] = useState<JSX.Element>();

	useEffect(() => {
		setReportImage(reportImages[Math.floor(Math.random() * reportImages.length)]);
	}, []);

	// istanbul ignore next
	const handleClick = useCallback(() => {
		reportClickedTracking({ domainName, reportTitle, reportId });
	}, []);

	return (
		<Item
			xl={3}
			lg={4}
			md={6}
			sm={6}
			xs={12}
			data-testid="dashboard-card"
			className="dashboard-card"
		>
			<DashboardCardStyled
				border="small"
				elevated={elevation}
				data-testid={`dashboard-card-${reportTitle}`}
				onMouseOver={() => setElevation("large")}
				onMouseLeave={() => setElevation("medium")}
			>
				<LinkStyled
					to={generatePath(routes.GLOBAL_REPORTS.REPORT_DETAILS, {
						domainName: convertToLowerCaseWithDash(domainName),
						reportId
					})}
					onClick={handleClick}
				>
					<div className="dashboard-card__heading">
						<Heading size="H4">{reportTitle}</Heading>
					</div>
					<div className="dashboard-card__body">{reportImage}</div>
					<div className="dashboard-card__footer">
						<Paragraph>Status: {status}</Paragraph>
						<Paragraph>Owner Name: {ownerName}</Paragraph>
						<Paragraph>{ownerEmail}</Paragraph>
					</div>
					<div className="dashboard-card__badge">
						<Badge.Status color="yellow" label={domainName} type="default" />
					</div>
				</LinkStyled>
			</DashboardCardStyled>
		</Item>
	);
};
